<template>
  <div style="min-height: 600px;">
    <subMenu key="subMenu" :activeTab="activeTab"></subMenu>
  <div class="flex-between main-box" key="videoProduct">
      <videoProduct></videoProduct>
      <newProduct></newProduct>
    </div>

    <ecosystemList key="ecosystemList"></ecosystemList>
    <login-form
      v-if="showLoginForm"
      :routeRedirect="routeRedirect"
      @loginSuccess="handleLoginSuccess"
      @close="handleLoginClose"
    /> 
  </div>
</template>
<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "./components/video/video.vue";
import newProduct from "./components/news/news.vue";
import ecosystemList from "./components/ecosystemList/ecosystemList.vue";
import partner from "./components/partner/partner.vue";
import { mapState } from "vuex";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      activeName: "home",
      activeTab: "home",
      showLoginForm: false,
      videoUrlList: [],
      newsList: [],
      navActive: 0,

      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() {},
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },

    routeRedirect() {
      return "/subwebsite/subwebsite";
    },
  },
  components: {
    LoginForm,
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "关于我们-首页";
  },
  methods: {
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },
  },
};
</script>

<style>
/* 进入动画 */
.v-enter-active {
  animation: move 1s;
}

/* 离开动画 */
.v-leave-active {
  animation: move 1s reverse;
}

@keyframes move {
  from {
    transform: translateX();
  }
  to {
    transform: translate(0);
  }
}
</style>

<style scoped lang="less">
.flex-between {
  display: flex;
  justify-content: space-between;
}
.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}
::v-deep
  .nav-width
  #content.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}
</style>
